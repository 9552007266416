exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-single-js": () => import("./../../../src/templates/blog-single.js" /* webpackChunkName: "component---src-templates-blog-single-js" */),
  "component---src-templates-category-list-js": () => import("./../../../src/templates/category-list.js" /* webpackChunkName: "component---src-templates-category-list-js" */),
  "component---src-templates-single-category-js": () => import("./../../../src/templates/single-category.js" /* webpackChunkName: "component---src-templates-single-category-js" */)
}

